.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
    overflow: scroll;

}
.modal.active {
    opacity: 1;
    pointer-events: all;
    z-index: 999;

}
.modal__content {
    border-radius: 12px;
    width: 90vw;
    /*height: 100vw;*/
    transform: scale(0.5);
    transition: 0.4s all;
}

.modal__content.active {
    transform: scale(1);
}

.wrapper__cart {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.image__modal {
    border-radius: 12px;

}