@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("./assets/fonts/Gilroy-Regular.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Regular.woff") format("woff"),
    url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@supports (-webkit-overflow-scrolling: touch){
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  outline: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.container {
  position: relative;
  /* padding: 0 20px; */
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;

  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  border-radius: 4px;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); */
  box-shadow: none;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(108, 172, 79, 0.4);
  border-radius: 0 0 5px 5px;
}

@media screen and (min-width: 743px) {
  body {
    background: rgba(251, 251, 251, 0.95);
  }

  #cart-check + label,
  #instruction-check + label {
    font-size: 22px;
  }

  #instruction-check + label::before {
    width: 33px;
    height: 33px;
    border: 2.7px solid #616a5c;
  }
}
.react-toast-notifications__toast.react-toast-notifications__toast--success.react-toast-notifications__toast__icon-wrapper {
  background-color: #83b16e !important;
}

.react-toast-notifications__toast.react-toast-notifications__toast--success {
  background-color: #eef8e9 !important;
}

